<template>
<div class="mobileContainer">
    <header-container>
        <ParticularOPDAppointmentHeader />
    </header-container>
    <div class="bodyContainer" v-if="dataLoading">
        <div style="width: 100%; height: 100%; background-color: white;">
                <div class="timingContainer">
                    <div style="display: flex; flex-direction: column; align-items: flex-start; gap: 8px;">
                        <div style="color: #828282; font-size: 14px; font-weight: 400;">{{$t("Customer.Appointments.Time_to_visit")}}:</div>
                        <div style="font-weight: 500; font-size: 14px;">{{ bookingInfo.packageInfo.timeInfo }}</div>
                    </div>
                    <div style="display: flex; flex-direction: column; align-items: flex-end; gap: 8px;">
                        <div style="color: #828282; font-size: 14px; font-weight: 400;">{{$t("Customer.PackageAppointments.booking_id")}}:</div>
                        <div style="font-weight: 600; font-size: 16px;">{{ bookingInfo.packageInfo.bookingID }}</div>
                    </div>
                </div>
                <div class="timingContainer">
                    <div style="display: flex; flex-direction: column; align-items: flex-start; gap: 8px;">
                        <div style="color: #828282; font-size: 14px; font-weight: 400;">{{$t("Customer.Appointments.Booked_Date")}}</div>
                        <div style="font-weight: 500; font-size: 14px;">{{ formatDate(bookingInfo.packageInfo.bookingDateNumber) }}</div>
                    </div>
                    <div style="display: flex; flex-direction: column; align-items: flex-end; gap: 8px;"  v-if="bookingInfo.packageInfo.isExtended">
                        <div style="color: #828282; font-size: 14px; font-weight: 400;">{{$t("Customer.Appointments.Extended_Date")}}</div>
                        <div style="font-weight: 500; font-size: 14px;">{{ formatDate(bookingInfo.packageInfo.extendedDateNumber) }}</div>
                    </div>
                </div>
                <div class="timingContainer">
                    <div style="display: flex; flex-direction: column; align-items: flex-start; gap: 8px;">
                        <div style="color: #828282; font-size: 14px; font-weight: 400;">{{$t("Customer.PackageAppointments.Package_start_date")}}</div>
                        <div style="font-weight: 500; font-size: 14px;">{{ bookingInfo.packageInfo.startDate }}</div>
                    </div>
                    <div style="display: flex; flex-direction: column; align-items: flex-end; gap: 8px;"  >
                        <div style="color: #828282; font-size: 14px; font-weight: 400;">{{$t("Customer.PackageAppointments.packag_end_date")}}</div>
                        <div style="font-weight: 500; font-size: 14px;">{{ bookingInfo.packageInfo.endDate }}</div>
                    </div>
                </div>
                <div style="padding: 20px;">
                    <div class="packageDetailContainer" >
                        <img :src="bookingInfo.packageInfo.packageBanner" alt="" style="border-top-left-radius: inherit; border-top-right-radius: inherit; height: 150px; object-fit: cover;">
                        <div style="width: 100%; padding: 16px; padding-top: 0; gap: 16px; display: flex; flex-direction: column; ">
                            <OPDPackageNameAndTestDetails  :showApplicableFor="true" :showBookSlot="false" :showPrice="false" :item="bookingInfo.packageInfo"/>
                            <div style="display: flex; flex-direction: column; gap: 4px; align-items: flex-start;">
                                <span style="color: #828282; font-size: 14px; font-weight: 400;">{{$t("Customer.CartPage.Address")}}: </span>
                                <div class="hospitalNameContainer">
                                    <div class="hospitalLogo">
                                        <img :src="bookingInfo.packageInfo.hospitalLogo" style="max-width: 100%; max-height: 100%; object-fit: cover; border-radius: 8px;"/>
                                    </div>
                                    <div class="hospitalName">
                                        <div style="font-size: 14px; font-weight: 500; color: #333333;">{{ bookingInfo.packageInfo.hospitalName }}</div>
                                        <div style="font-size: 12px; font-weight: 400; color: #4f4f4f;">{{ bookingInfo.packageInfo.hospitalAddress }}</div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                    <AppointmentPatientInfoComponent  :patientInfo="bookingInfo.patientInfo"/>
                    <AppointmentPatientContactComponent :patientInfo="bookingInfo.patientInfo"/> 
                    <div style="display: flex; flex-direction: column; justify-content: flex-start; align-items: flex-start; margin-top: 25px;">
                    <div style="font-size: 14px; font-weight: 600; margin-bottom: 10px;">{{$t("Customer.PackageAppointments.payment_info")}}</div>
                    <div style="width: 100%; display: flex; flex-direction: column; justify-content: flex-start; gap: 20px; border: 1px solid #e0e0e0; border-radius: 8px; padding: 16px;">
                        <div style="display: flex; align-items: center; justify-content: space-between;">
                            <span style="font-weight: 500; font-size: 14px;">{{$t("Customer.PackageAppointments.Total_amount")}}:</span>
                            <span style="font-weight: 600; font-size: 16px; color: #48acef;">{{ bookingInfo.packageInfo.price }} MMK</span>
                        </div>
                    </div>
                </div>
                </div>
        </div>
    </div>
</div>
</template>

<script>
import axios from "axios";
import HeaderContainer from '../../../templates/headerContainerOPDBooking.vue';
import MainContainer from '../../../templates/mainContainer.vue';
import { axios_auth_instance_hba_customer } from '../../../utils/axios_utils';
import HospitalSkeletonLoader from '../../OPDBookingPages/componentUsedInPage/opdHomePage/hospitalSkeletonLoader.vue';
import AppointmentPatientContactComponent from '../../OPDBookingPages/componentUsedInPage/particularOPDAppointmentPage/appointmentPatientContactComponent.vue';
import AppointmentPatientInfoComponent from '../../OPDBookingPages/componentUsedInPage/particularOPDAppointmentPage/appointmentPatientInfoComponent.vue';
import ParticularOPDAppointmentHeader from '../../OPDBookingPages/componentUsedInPage/particularOPDAppointmentPage/particularOPDAppointmentHeader.vue';
import OPDPackageNameAndTestDetails from '../componentsUsedInPages/OPDPackageViewHospitalPage/OPDPackageNameAndTestDetails.vue';


export default {
  components: {HeaderContainer, MainContainer, ParticularOPDAppointmentHeader, HospitalSkeletonLoader, OPDPackageNameAndTestDetails, AppointmentPatientInfoComponent, AppointmentPatientContactComponent,  },
    name: 'particularOPDPackageAppointment',
    data: function() {
        return {
            token: null,
            bookingID: '',
            todayAppointment: [],
            pageLoading: true,
            ongoingAppointment: [],
            bannerURL: '',
            scrollableDiv: null,
            searchMeDiv: null,
            allDetailsDiv: null,
            dependent_members: [],
            currentCustomer: null,
            doctorList: [],
            sheet: true,
            selectedReason: null,
            bottomSheet: false,
            bookingInfo: {},
            dataLoading: true,
            cancellLoader: false,
            doctorData: null,
            viewDoctorModel: false,
            viewDoctorLoading: false,
            tokenList: [],
            val: false,
            currentTime: '',
            patientInfo: {
                name: 'Prakersh',
                age: 25,
                gender: 'Male'
            },
            patientContact: {
                mobile: '90903423432',
                streetAddress: 'Varanasi',
                township: 'Yangoon'
            }
        };
    },
    computed: {
        isCancelDisable() {
            switch(this.tokenList[this.bookingInfo.appointmentData.doctorInfo.tokenNumber - 1].patientStatus) {
                case 'ARRIVED':
                case 'YET_TO_ARRIVE':
                    return false;
                default:
                    return true;
            }
        }
    },
    async mounted() {
        if (!window.xm)
			this.token = this.$cookies.get('customerToken');
		else
			this.token = localStorage.getItem('customerToken');

        this.currentTime = this.getCurrentTime12HourFormat();
        this.bookingID = this.$route.params.bookingID;
        try {
            let apiResponse = await axios_auth_instance_hba_customer.get(`/appointment/package/particularAppointment/${this.bookingID}`);
            this.bookingInfo = apiResponse.data.bookingInfo;
        } catch (error) {
            console.log('Error', error);
        }

        this.userPageViewTable();
    },

    methods: {
        userPageViewTable() {
            if (this.token === null) {
                var userPageViewTableBody = {
                    pageName : 'HBAPackageAppointmentDetails',
                    typeOfUser: 'CUSTOMER'
                }
            } else {
                var userPageViewTableBody = {
                    pageName: 'HBAPackageAppointmentDetails',
                    typeOfUser: 'CUSTOMER',
                    token: this.token
                }
            }
            axios.post(process.env.VUE_APP_BACKEND_URL+"/userPageViewTable", userPageViewTableBody)
            .catch((userPageViewTableError) => {
                console.log('[Error][userPageTableError] Error in adding userPageViewTable', userPageViewTableError);
            });
        },
        formatDate(inputDate) {
                inputDate = inputDate.toString();

                let year = inputDate.slice(0, 4);
                let month = inputDate.slice(4, 6);
                let day = inputDate.slice(6, 8);

                return `${year}-${month}-${day}`;
            },
        getCurrentTime12HourFormat() {
            const date = new Date();
            let hours = date.getHours();
            const minutes = date.getMinutes();
            const ampm = hours >= 12 ? 'PM' : 'AM';
            hours = hours % 12;
            hours = hours ? hours : 12;

            const minutesStr = minutes < 10 ? '0' + minutes : minutes;

            const timeStr = `${hours}:${minutesStr} ${ampm}`;

            return timeStr;
        },

        async toggleViewDoctorDialog(value) {
            if(value) {
                try {
                    if (this.doctorData == null) {
                        this.viewDoctorLoading = true;
                        let apiResponse = await axios_auth_instance_hba_customer(`/opd/doctorInfo/${this.bookingInfo.appointmentData.doctorInfo.doctorID}`);
                        this.doctorData = apiResponse.data.doctorDetails;
                    }
                    this.viewDoctorModel = true;
                } catch (error) {
                    console.log(error);
                } finally {
                    this.viewDoctorLoading = false;
                }
            } else {
                this.viewDoctorModel = false;
            }
        },
        async cancellAppointment() {
            try {
                this.cancellLoader = true;
                await axios_auth_instance_hba_customer.put(`/appointment/cancell/${this.bookingID}`);
                this.$router.push({
                    name: 'listOPDAppointmentsPage'
                });
            } catch (error) {
                console.log(error);
            } finally {
                this.cancellLoader = false;
            }
        },
        toggleBottomSheet() {
            this.bottomSheet = !this.bottomSheet;
        },
        calculateClassForHospitalInfo() {
            return 'topBorder';
            if (this.dataLoading) return 'topBorder';
            // if (this.completedFlag) return 'topBorder';
            // return 'allBorder';
        }
    },
};

</script>
<style  scoped>
.commentBox {
    width: 100%;
    border: 1px solid #e0e0e0;
    border-radius: 8px;
    padding: 12px 13px 12px 13px;

}
.dropdown {
    width: 100%;
    border: 1px solid#e0e0e0;
    padding: 12px 13px 12px 13px;
    border-radius: 8px;

}
.bottomSheet {
    height: 475px;
    width: 100%;
    background: white;
    border-top-left-radius: 12px;
    border-top-right-radius: 12px;
    border: 1px solid #e0e0e0;
    position: absolute;
    bottom: 0;
    max-width: 425px;
}
.timingContainer {
    display: flex;
    justify-content: space-between;
    padding: 20px;
    border-bottom: 1px solid #f2f2f2;
}
.TokenContainer {
    width: 150px;
    height: 44px;
    padding: 8px 8px 8px 16px;
    border-radius: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 15px;
    margin-top: 16px;
    background: #1467BF1A;
}
.mobileContainer {
    margin-left: auto;
    margin-right: auto;
    width: 425px;
    background-color: white;
    height: 90vh;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}
.bodyContainer {
    width: 100%;
    flex: 1;
    /* height: 85vh; */
    overflow: auto;
    background-color: white;
}
.v-sheet {
    border-top-left-radius: 12px;
    border-top-right-radius: 12px;
}
.selectRegionBottomSheetHeading {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 12px 16px 12px 16px;
    border-bottom: 1px solid #e8e8eb;
}
.stateChipsContainer {
    padding: 20px 16px 20px 16px;
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
    flex-grow: 1;
    overflow-y: scroll;
    max-height: 200px;
    border-top: 1px solid #e8e8eb;

}
.stateBottomSheetBottomBar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 14px 16px 12px 16px;
    border-top: 1px solid #e8e8eb;
}
.searchStateBottomContainer {
    display: flex;
    flex-direction: column;
}
.v-dialog__content >>> .v-bottom-sheet {
    box-shadow: none !important;
}
.doctorName {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 2px;
}
.doctorProfile {
    border-radius: 50%;
    max-height: 56px;
    max-width: 56px;
    height: 56px;
    width: 56px;
    border: 1px solid #e0e0e0;
}
img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.doctorNameAndLogo {
    display: flex;
    align-items: center;
    gap: 12px;
    flex-grow: 1;
}
.specialtyBox {
    display: flex;
    justify-content: center;
    align-items: center;
    background: #E4F5FF;
    padding: 3px 8px 3px 8px;
}
.hospitalName {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
}
.hospitalLogo {
    max-height: 40px;
    max-width: 40px;
    height: 40px;
    width: 40px;
    border: 1px solid #e0e0e0;
    border-radius: 8px;
}
.hospitalNameAndLogo {
    display: flex;
    align-items: flex-start;
    gap: 16px;
    font-size: 14px !important;
}
.allBorder {
    border: 1px solid #e0e0e0;
    border-radius: 8px;
    margin-top: 16px;
}
.topBorder {
    border-top: 1px solid #e0e0e0;
    margin-top: 16px;

}
.packageDetailContainer {
    /* margin: 0 16px; */
    /* padding: 16px; */
    background: white;
    border-radius: 8px;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    border: 1px solid #e0e0e0;

    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
    cursor: pointer;
    z-index: -2122;
}
.hospitalNameContainer {
    display: flex;
    gap: 8px;
}
.hospitalLogo {
    height: 48px;
    width: 48px;
    border: 1px solid #e0e0e0;
    border-radius: 8px;
}
.hospitalName {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}
@media screen and (max-width: 425px){
.mobileContainer {
    width: 100%;
}
}
</style>